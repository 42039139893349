import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchCars } from '../actions'
import { getCars } from '../reducers'
import {
    Box,
    Button,
    useColorModeValue
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'

const columns = [
    { name: 'id', selector: row => row.id, sortable: true },
    { name: 'Tipo de Vehiculo', selector: row => row.clase, sortable: true },
    { name: 'Modelo', selector: row => row.modelo, sortable: true },
    { name: 'Marca', selector: row => row.marca, sortable: true },
    { name: 'Disponibilidad', selector: row => row.disponibilidad, sortable: true},
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class CarList extends React.Component {

    componentDidMount() {
        this.props.fetchCars()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { cars } = this.props

        return (
            <Layout>
                <Box
                    w={'full'}
                    bg={this.getColor}
                    rounded={'md'}
                    m={3}
                    p={6}
                >
                    <DataTable
                        pagination
                        columns={columns}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        data={cars.map(car => {
                            return {
                                id: car.id,
                                clase: car.cClase,
                                modelo: car.cModelo,
                                marca: car.cMarca,
                                disponibilidad: car.cDisponibilidad,
                                opcion: <Link to={`car/${car.id}/edit`}><Button colorScheme="teal" variant="outline">Editar</Button></Link>
                            }
                        })}
                    />
                </Box>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    cars: getCars(state)
})

const mapDispatchToProps = { fetchCars }

export default connect(mapStateToProps, mapDispatchToProps)(CarList)