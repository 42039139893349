import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchClients } from '../actions'
import { getClients } from '../reducers'
import {
    Box,
    Button,
    useColorModeValue
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'


const columns = [
    { name: 'Codigo', selector: row => row.codigo, sortable: true },
    { name: 'Nombre', selector: row => row.nombre, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Teléfono', selector: row => row.telefono, sortable: true },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class ClientList extends React.Component {

    componentDidMount() {
        this.props.fetchClients()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { clients } = this.props

        return (
            <Layout>
                <Box
                    w={'full'}
                    bg={this.getColor}
                    rounded={'md'}
                    m={3}
                    p={6}
                >
                    <DataTable
                        pagination
                        columns={columns}
                        defaultSortAsc={true}
                        defaultSortFieldId={1}
                        data={clients.map(client => {
                            return {
                                id: client.id,
                                codigo: client.cCodigo,
                                nombre: `${client.cNombres} ${client.cApellidoPaterno}`,
                                email: client.cEmail,
                                telefono: client.cTelefono,
                                opcion: <Link to={`client/${client.id}/edit`}><Button colorScheme="teal" variant="outline">Ver más</Button></Link>
                            }
                        })}
                    />
                </Box>
            </Layout >
        )
    }
}

const mapStateToProps = (state) => ({
    clients: getClients(state)
})

const mapDispatchToProps = { fetchClients }

export default connect(mapStateToProps, mapDispatchToProps)(ClientList)