import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchUsers } from '../actions'
import {
    getUsers,
} from '../reducers'
import {
    Box,
    Button,
    useColorModeValue
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'

const columns = [
    { name: 'Nombre', selector: row => row.nombre, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Rol', selector: row => row.rol_id, sortable: true },
    { name: 'Bandeja', selector: row => row.opcion2, sortable: false },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class UserList extends React.Component {

    componentDidMount() {
        const { fetchUsers } = this.props
        fetchUsers()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { users } = this.props
        return (
            <Layout>
                <Box
                    w={'full'}
                    bg={this.getColor}
                    rounded={'md'}
                    m={3}
                    p={6}
                >
                    <DataTable
                        pagination
                        columns={columns}
                        data={users.map(user => {
                            return {
                                id: user.id,
                                nombre: `${user.nombre} ${user.apellido}`,
                                email: user.email,
                                rol_id: user.rol_usuario,
                                opcion: <Link to={`user/${user.id}/edit`}><Button colorScheme="teal" variant="outline">Editar</Button></Link>,
                                opcion2: user.rol_id === 3 && <Link to={`user/${user.id}/bandeja`}><Button colorScheme="teal"> Bandeja</Button></Link>

                            }
                        })}
                    />
                </Box >
            </Layout >
        )
    }
}

const mapStateToProps = (state) => ({
    users: getUsers(state)
})

const mapDispatchToProps = { fetchUsers }

export default connect(mapStateToProps, mapDispatchToProps)(UserList)