import axios from 'axios'
import {
    FETCH_ENTITIES,
    APIpath,
    addToast
} from '../actions'

const setEntity = (name, entity) => ({
    type: FETCH_ENTITIES,
    name,
    entity
})

export const fetchEntityWithId = (entity, id) => (dispatch) => {
    axios.get(`${APIpath}/entity/${entity}/${id}`)
        .then(function (response) {
            dispatch(setEntity(entity, response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchEntity = (entity) => (dispatch) => {
    axios.get(`${APIpath}/entity/${entity}`)
        .then(function (response) {
            dispatch(setEntity(entity, response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchRoles = (entity) => (dispatch) => {
    axios.get(`${APIpath}/rol`)
        .then(function (response) {
            dispatch(setEntity(entity, response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}