import React from "react"
import { connect } from 'react-redux'
import { fetchEntity, fetchEntityWithId, fetchBandejaCliente, updateBandejaCliente } from '../actions'
import { getBandejaUsuario, getEntity } from '../reducers'
import {
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    Select,
    Textarea,
    HStack,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'

class ClientBandeja extends React.Component {

    getColor = () => useColorModeValue('white', 'gray.800')

    tiempo_espera = (inicio, fin) => {
        let inicioDate = new Date(inicio)
        let finDate = new Date(fin)
        let time = Math.abs(finDate - inicioDate)
        var ms = time % 1000
        time = (time - ms) / 1000
        var secs = time % 60
        time = (time - secs) / 60
        var mins = time % 60
        var hrs = (time - mins) / 60

        return hrs + ':' + mins + ':' + secs
    }

    componentDidMount() {
        this.props.fetchBandejaCliente(this.props.match.params.id)
        this.props.fetchEntity('bandeja')
    }

    render() {

        const { bandeja, bandeja_acciones, updateBandejaCliente } = this.props

        return (
            <Layout>

                <Box w={'full'} bg={this.getColor} rounded={'md'} m={3} p={6} >

                    {bandeja !== null &&
                        <Box>
                            <HStack>
                                <FormControl>
                                    <FormLabel>Asesor: </FormLabel>
                                    <Input readOnly variant='flushed' value={bandeja.asesor} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>N* Solicitud: </FormLabel>
                                    <Input readOnly variant='flushed' value={bandeja.id} />
                                </FormControl>
                            </HStack>
                            <HStack>
                                <FormControl>
                                    <FormLabel>Nombre del cliente: </FormLabel>
                                    <Input readOnly variant='flushed' value={bandeja.cliente} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Código del cliente: </FormLabel>
                                    <Input readOnly variant='flushed' value={bandeja.cliente_id + bandeja.codigo} />
                                </FormControl>
                            </HStack>

                            <HStack>
                                {
                                    bandeja.created_at !== null &&
                                    <FormControl>
                                        <FormLabel>Fecha de asignación: </FormLabel>
                                        <Input readOnly variant='flushed' value={new Date(bandeja.created_at).toISOString().split('T')[0]} />
                                    </FormControl>
                                }
                                {bandeja.fecha_accion !== null &&
                                    <FormControl>
                                        <FormLabel>Fecha de Acción: </FormLabel>
                                        <Input readOnly variant='flushed' value={new Date(bandeja.fecha_accion).toISOString().split('T')[0]} />
                                    </FormControl>
                                }
                            </HStack>

                            <HStack>
                                {
                                    bandeja.updated_at !== null &&
                                    <FormControl>
                                        <FormLabel>Fecha de ultimo cambio: </FormLabel>
                                        <Input readOnly variant='flushed' value={bandeja.updated_at ? new Date(bandeja.updated_at).toISOString().split('T')[0] : ""} />
                                    </FormControl>
                                }
                                {bandeja.fecha_accion !== null &&
                                    <FormControl>
                                        <FormLabel>Tiempo de respuesta: </FormLabel>
                                        <Input readOnly variant='flushed' value={this.tiempo_espera(bandeja.created_at, bandeja.fecha_accion)} />
                                    </FormControl>
                                }
                            </HStack>

                            <FormControl>
                                <FormLabel>Estado del cliente</FormLabel>
                                <Input Input readOnly variant='flushed' value={bandeja.estado || 'Pendiente'} />
                            </FormControl>

                            <Formik
                                initialValues={{
                                    resultado: bandeja.bandeja_accion_id || '',
                                    observaciones: bandeja.observaciones || ''
                                }}
                                validate={values => FormValidator([
                                    { field: 'resultado', value: values.resultado, rules: ['required'] },
                                    { field: 'observaciones', value: values.observaciones, rules: ['required'] }
                                ])}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    Promise.resolve(updateBandejaCliente({ ...values, id: bandeja.id }))
                                        .then(() => {
                                            setSubmitting(false)
                                            resetForm()
                                        })
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>

                                        <FormControl isInvalid={errors.resultado && touched.resultado}>
                                            <FormLabel>Resultado del cliente</FormLabel>
                                            <Select size="lg" name={"resultado"} onChange={handleChange} onBlur={handleBlur} value={values.resultado} >
                                                <option value={""}>Seleccione resultado del cliente</option>
                                                {typeof bandeja_acciones !== 'undefined' && bandeja_acciones.map(bandeja_accion =>
                                                    <option value={bandeja_accion.id} key={bandeja_accion.id}>{bandeja_accion.nombre}</option>
                                                )}
                                            </Select>
                                            <FormErrorMessage>{errors.resultado}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={errors.observaciones && touched.observaciones}>
                                            <FormLabel>Observaciones</FormLabel>
                                            <Textarea size="lg" name={"observaciones"} onChange={handleChange} onBlur={handleBlur} value={values.observaciones} />
                                            <FormErrorMessage>{errors.observaciones}</FormErrorMessage>
                                        </FormControl>
                                        <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                        </Button>
                                    </form>
                                )}
                            </Formik>

                        </Box>
                    }

                </Box>
            </Layout>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    bandeja_acciones: getEntity(state, 'bandeja'),
    bandeja: getBandejaUsuario(state, ownProps.match.params.id)
})

const mapDispatchToProps = { fetchEntity, fetchEntityWithId, fetchBandejaCliente, updateBandejaCliente }

export default connect(mapStateToProps, mapDispatchToProps)(ClientBandeja)
