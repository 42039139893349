import {
    CONFIGURATION,
    LOADING,
    REDIRECT,
    ADD_TOAST,
    REMOVE_TOAST,
    APIpath,
} from '../actions'
import axios from 'axios'

const setConfiguration = (config) => ({
    type: CONFIGURATION,
    config
})

export const setLoading = (status) => ({
    type: LOADING,
    status
})

export const setRedirect = (status) => ({
    type: REDIRECT,
    status
})

export const addToast = (status, message, id = "default") => ({
    type: ADD_TOAST,
    status, message, id
})

export const removeToast = () => ({
    type: REMOVE_TOAST
})

export const fetchConfiguration = () => (dispatch) => {
    axios.get(`${APIpath}/configuration`)
        .then(function (response) {
            dispatch(setConfiguration(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateConfiguration = (values) => (dispatch) => {
    axios.post(`${APIpath}/configuration`, values)
        .then(function (response) {
            dispatch(setConfiguration(response.data))
            dispatch(addToast('success', 'Configuración actualizada'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}