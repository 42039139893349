import React from 'react'
import { Link } from 'react-router-dom'
import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
    IconButton,
    Flex,
    HStack,
    Text,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Center
} from '@chakra-ui/react'
import Sidebar from '../components/Sidebar'
import {
    FiMenu,
    FiChevronDown,
} from 'react-icons/fi'
import { ColorModeSwitcher } from '../ColorModeSwitcher'


const NavbarPanel = ({ logout, user, menu }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box>
            <Sidebar onClose={() => onClose} display={{ base: 'none', md: 'block' }} linksItems={menu} />
            <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} >
                <DrawerContent>
                    <Sidebar onClose={onClose} linksItems={menu} />
                </DrawerContent>
            </Drawer>
            <Flex
                ml={{ base: 0, md: 60 }} px={{ base: 4, md: 4 }}
                height="20" alignItems="center" bg={useColorModeValue('white', 'gray.800')}
                borderBottomWidth="1px" borderBottomColor={useColorModeValue('green.400', 'green.800')} justifyContent={{ base: 'space-between', md: 'flex-end' }}
            >
                <IconButton display={{ base: 'flex', md: 'none' }} onClick={onOpen} variant="outline" aria-label="open menu" icon={<FiMenu />} />
                <HStack spacing={{ base: '0', md: '6' }}>
                    <ColorModeSwitcher justifySelf="flex-end" />
                    <Flex alignItems={'center'}>
                        <Menu>
                            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <Text>{`${user.nombre} ${user.apellido}`}</Text>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList alignItems={'center'}>
                                <Center> {user.rol_usuario}</Center>
                                <MenuDivider />
                                <Link to={"/profile"}><MenuItem>Perfil </MenuItem></Link>
                                <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            </Flex>
        </Box>
    )
}

export default NavbarPanel