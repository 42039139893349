import React from "react"
import {
  Box,
  Container,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react"
import Thumb from "./Thumb"

export default function InputFile(props) {

  const { setFieldValue, name, label, value } = props
  return (
    <Container my="6">
      <Box borderColor="gray.300" borderWidth="2px" rounded="md" shadow="sm" role="group" transition="all 150ms ease-in-out" _hover={{ shadow: "md" }} >
        <Box position="relative" height="100%" width="100%">
          <Stack padding={3}>
            <Heading fontSize="lg" color="gray.700" fontWeight="bold"> {label} </Heading>
          </Stack>
          <Input type="file" name={name} height="100%" width="100%" position="absolute" top="0" left="0" opacity="0" aria-hidden="true" accept="image/*" onChange={(event) => { setFieldValue(name, event.currentTarget.files[0]) }} />
        </Box>
      </Box>
      {value &&
        <Box borderWidth="2px" rounded="md" shadow="sm" justifyContent={"center"} display={"flex"}>
          <Thumb file={value} />
        </Box>
      }
    </Container>
  )
}
