import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Icon } from '@chakra-ui/react'

const NavItem = ({ icon, children, path, ...rest }) => {
    return (
        <Link to={path}>
            <Flex align="center" p="4" mx="4" borderRadius="lg" role="group" cursor="pointer" _hover={{ bg: 'green.400', color: 'white' }} {...rest}>
                {icon && (<Icon mr="4" fontSize="16" _groupHover={{ color: 'white' }} as={icon} />)}
                {children}
            </Flex>
        </Link>
    )
}

export default NavItem