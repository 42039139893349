const user= (state = [], action) => {

    switch(action.type){
        case 'FETCH_USER': 
        return state.map( user => {
            if(action.user.id === user.id) return action.user
            return user
        })
        case 'FETCH_USERS': return action.users 
        case 'LOG_OUT': return []
        default: return state
    }
}

export const getUsers = (state) => state
export const getUser = (state, id) => state.reduce((p, user) => {
    if (user.id === Number(id)) return user
    return p
}, {})


export default user