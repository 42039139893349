import React from 'react';
import { connect } from 'react-redux';
import { getAuth, getRole } from '../reducers';
import { Route, Redirect } from 'react-router-dom';

const roleVerification = (role, accessRole) => role === accessRole

const PrivateRoute = ({
    component: Component,
    isAuth,
    role,
    accessRole,
    ...rest
}) => {
    const path = '/'
    const redirectPath = '/login'
    return <Route {...rest} render={(props) => (
        isAuth
            ? roleVerification(role, accessRole)
                ? <Component {...props} />
                : <Redirect to={path} />
            : <Redirect to={redirectPath} />
    )} />
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true,
    role: getRole(state)
})

export default connect(mapStateToProps, undefined)(PrivateRoute);