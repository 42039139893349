const contacts = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_CONTACTS': {
            return action.contacts
        }
        default: return state
    }
}

export const getContacts = (state) => state
export const getContact = (state, id) => state.reduce((p, contact) => {
    if (contact.id === Number(id)) return contact
    return p
}, {})

export default contacts