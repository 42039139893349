const testimonial = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_TESTIMONIALS': return action.testimonials
        default: return state
    }
}

export const getTestimonials = (state) => state
export const getTestimonial = (state, id) => state.reduce((p, testimonial) => {
    if ( Number(testimonial.id) === Number(id)) return testimonial
    return p
}, {})

export default testimonial