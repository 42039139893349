import { Box, Flex, useColorModeValue, Stack } from "@chakra-ui/react"
import React from "react"


const AuthpageLayout = (props) => {

    const { children } = props
    const gray = useColorModeValue('gray.50', 'gray.800')
    const white = useColorModeValue('white', 'gray.700')
    return (
        <Flex minH={'100vh'} align={'start'} justify={'center'} bg={gray}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Box rounded={'lg'} bg={white} boxShadow={'lg'} p={8}>
                    {children}
                </Box>
            </Stack>
        </Flex>
    )
}

export default AuthpageLayout