import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchTestimonials } from '../actions'
import { getTestimonials } from '../reducers'
import {
    Box,
    Button,
    useColorModeValue
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'

const columns = [
    { name: '#', selector: row => row.id, sortable: true },
    { name: 'Titulo', selector: row => row.titulo, sortable: true },
    { name: 'Activo', selector: row => row.activo, sortable: true },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class TestimonialList extends React.Component {

    componentDidMount() {
        this.props.fetchTestimonials()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { testimonials } = this.props

        return (
            <Layout>
                <Box
                    w={'full'}
                    bg={this.getColor}
                    rounded={'md'}
                    m={3}
                    p={6}
                >
                    <DataTable
                        pagination
                        columns={columns}
                        data={testimonials.map(testimonial => {
                            return {
                                id: testimonial.id,
                                titulo: testimonial.cTitulo,
                                activo: testimonial.active ? 'Visible' : 'Inactivo',
                                opcion: <Link to={`/testimonial/${testimonial.id}/edit`}><Button colorScheme="teal" variant="outline">Editar</Button></Link>
                            }
                        })}
                    />
                </Box>
                <Link to={`/testimonial/create`}><Button colorScheme="easycar2">Nuevo testimonio</Button></Link>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    testimonials: getTestimonials(state)
})

const mapDispatchToProps = { fetchTestimonials }

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialList)