import axios from 'axios'
import {
    FETCH_USERS,
    FETCH_USER,
    APIpath,
    setRedirect,
    addToast
} from '../actions'

export const setUsers = (users) => ({
    type: FETCH_USERS,
    users
})

const setUser = (user) => ({
    type: FETCH_USER,
    user
})

export const fetchUsers = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/user`)
        .then(function (response) {
            dispatch(setUsers(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const saveUser = (values) => dispatch => {
    axios.post(`${APIpath}/user/store`, values)
        .then(function (response) {
            dispatch(setRedirect(true))
            dispatch(addToast('success', 'Usuario creado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateUser = (values) => dispatch => {
    axios.post(`${APIpath}/user/update/`, values)
        .then(function (response) {
            dispatch(setUser(response.data))
            dispatch(addToast('success', 'Usuario actualizado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateProfile = (values) => dispatch => {
    axios.post(`${APIpath}/user/update-profile/`, values)
        .then(function (response) {
            dispatch(setUser(response.data))
            dispatch(addToast('success', 'Usuario actualizado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateUserPassword = (values) => dispatch => {
    axios.post(`${APIpath}/user/password/`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Usuario actualizado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export {
    setUser
}