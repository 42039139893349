import axios from 'axios'
import {
    FETCH_CLIENT,
    FETCH_CLIENTS,
    FETCH_BANDEJA,
    APIpath,
    setRedirect,
    addToast
} from '../actions'

const setClients = (clients) => ({
    type: FETCH_CLIENTS,
    clients
})

const setClient = (client) => ({
    type: FETCH_CLIENT,
    client
})

const setBandeja = (bandeja) => ({
    type: FETCH_BANDEJA,
    bandeja
})

export const fetchClients = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente`)
        .then(function (response) {
            dispatch(setClients(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchClient = (id) => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente/find/${id}`)
        .then(function (response) {
            dispatch(setClients(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const saveClient = (values) => dispatch => {

    //Revision de existencia
    axios.get(`${APIpath}/cliente/verify-existing-mail/${values.email}`)
        .then(function (response) {
            if (response.data.length === 0) {

                axios.post(`${APIpath}/cliente/store`, values)
                    .then(function (response) {
                        dispatch(setRedirect(true))
                        dispatch(addToast('success', 'Cliente creado'))
                    })
                    .catch(function (err) {
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
            } else {
                dispatch(addToast('error', 'Usuario ya existente'))
            }
        }).catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateClient = (values) => dispatch => {
    axios.post(`${APIpath}/cliente/updateBack/`, values)
        .then(function (response) {
            dispatch(setClients(response.data))
            dispatch(addToast('success', 'Cliente actualizado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateClientPassword = (values) => dispatch => {
    axios.post(`${APIpath}/cliente/password/`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Cliente clave actualizada'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchBandeja = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente/bandeja`)
        .then(function (response) {
            dispatch(setBandeja(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchBandejaUsuario = (id) => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente/bandeja/usuario/${id}`)
        .then(function (response) {
            dispatch(setBandeja(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchBandejaCliente = (id) => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/cliente/bandeja/cliente/${id}`)
        .then(function (response) {
            dispatch(setBandeja(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateBandejaCliente = (values) => (dispatch) => {
    dispatch(setRedirect(false))
    axios.post(`${APIpath}/cliente/bandeja`, values)
        .then(function (response) {
            dispatch(setBandeja(response.data))
            dispatch(addToast('success', 'cambios guardados'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export {
    setClient
}