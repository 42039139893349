import React from "react"
import { connect } from 'react-redux'
import { updateConfiguration } from '../actions'
import { getConfig } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    useColorModeValue,
    Textarea,
    HStack
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'


const Configuration = props => {

    const { updateConfiguration, config } = props
    const boxBg = useColorModeValue('white', 'gray.800')
    return (
        <Layout>
            <Box w={'full'} bg={boxBg} rounded={'md'} m={3} p={6} >
                <Formik
                    initialValues={{
                        ubicacion: config.ubicacion || '',
                        telefono: config.telefono || '',
                        whatsapp: config.whatsapp || '',
                        facebook: config.facebook || '',
                        instagram: config.instagram || '',
                        youtube: config.youtube || '',
                        tiktok: config.tiktok || '',
                        horario: config.horario || '',
                        maps: config.maps || '',
                    }}
                    validate={values => FormValidator([
                        { field: 'ubicacion', value: values.ubicacion, rules: ['required'] },
                        { field: 'telefono', value: values.telefono, rules: ['required'] },
                        { field: 'horario', value: values.horario, rules: ['required'] }
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        Promise.resolve(updateConfiguration(values))
                            .then(() => {
                                setSubmitting(false)
                                resetForm()
                            })
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>

                            <FormControl isInvalid={errors.ubicacion && touched.ubicacion}>
                                <FormLabel>Ubicación</FormLabel>
                                <Textarea type="text" name='ubicacion' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.ubicacion} />
                                <FormErrorMessage>{errors.ubicacion}</FormErrorMessage>
                            </FormControl>
                            <HStack>


                                <FormControl isInvalid={errors.horario && touched.horario}>
                                    <FormLabel>Horario de atención</FormLabel>
                                    <Input type={'text'} size="lg" name={"horario"} onChange={handleChange} onBlur={handleBlur} value={values.horario} />
                                    <FormErrorMessage>{errors.horario}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={errors.telefono && touched.telefono}>
                                    <FormLabel>Teléfono</FormLabel>
                                    <Input type={'text'} size="lg" name={"telefono"} onChange={handleChange} onBlur={handleBlur} value={values.telefono} />
                                    <FormErrorMessage>{errors.telefono}</FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <HStack>

                                <FormControl isInvalid={errors.instagram && touched.instagram}>
                                    <FormLabel>Instagram</FormLabel>
                                    <Input type="text" name='instagram' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.instagram} />
                                    <FormErrorMessage>{errors.instagram}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={errors.whatsapp && touched.whatsapp}>
                                    <FormLabel>WhatsApp</FormLabel>
                                    <Input type={'text'} size="lg" name={"whatsapp"} onChange={handleChange} onBlur={handleBlur} value={values.whatsapp} />
                                    <FormErrorMessage>{errors.whatsapp}</FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <HStack>
                                <FormControl isInvalid={errors.facebook && touched.facebook}>
                                    <FormLabel>Facebook</FormLabel>
                                    <Input type={'text'} size="lg" name={"facebook"} onChange={handleChange} onBlur={handleBlur} value={values.facebook} />
                                    <FormErrorMessage>{errors.facebook}</FormErrorMessage>
                                </FormControl>


                                <FormControl isInvalid={errors.youtube && touched.youtube}>
                                    <FormLabel>YouTube</FormLabel>
                                    <Input type={'text'} size="lg" name={"youtube"} onChange={handleChange} onBlur={handleBlur} value={values.youtube} />
                                    <FormErrorMessage>{errors.youtube}</FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <HStack>

                                <FormControl isInvalid={errors.tiktok && touched.tiktok}>
                                    <FormLabel>Tiktok</FormLabel>
                                    <Input type={'text'} size="lg" name={"tiktok"} onChange={handleChange} onBlur={handleBlur} value={values.tiktok} />
                                    <FormErrorMessage>{errors.tiktok}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={errors.maps && touched.maps}>
                                    <FormLabel>Enlace de Maps</FormLabel>
                                    <Input type={'text'} size="lg" name={"maps"} onChange={handleChange} onBlur={handleBlur} value={values.maps} />
                                    <FormErrorMessage>{errors.maps}</FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Guardar cambios'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    config: getConfig(state)
})

const mapDispatchToProps = { updateConfiguration }

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)
