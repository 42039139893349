import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { useToast } from "@chakra-ui/react"
import { removeToast } from "../actions"
import { getActiveToast } from "../reducers"
import { useEffect } from "react"


const titleForStatus = (status) => {
    switch(status){
        case 'success': return 'Exito'
        case 'error': return 'Error'
        case 'info': return 'Información'
        case 'warning': return 'Alerta'
        default: return 'Aviso'
    }
}

const Toast = (props) => {
    const toastU = useToast()
    const { toast, actions } = props
    const { removeToast } = actions
    return (
        <div>
            {
                useEffect(() => {
                    toast.active && !toastU.isActive(toast.id) &&
                        toastU({
                            id: toast.id,
                            title: titleForStatus(toast.status),
                            description: toast.message,
                            status: toast.status,
                            duration: 5000,
                            isClosable: true,
                        })
                        && removeToast()
                })
            }
        </div>
    )
}


const mapStateToProps = state => ({
    toast: getActiveToast(state)
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ removeToast }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Toast)