import axios from 'axios'
import {
    LOG_IN,
    LOG_OUT,
    FETCH_MENUS,
    setUsers,
    APIpath,
    addToast
} from '../actions'


const log_in = (uid) => ({
    type: LOG_IN,
    uid
})

const log_out = () => ({
    type: LOG_OUT
})

const set_menu = (menu) => ({
    type: FETCH_MENUS,
    menu
})

export const login = (values) => (dispatch) => {
    axios.post(`${APIpath}/user/auth`, values)
        .then(function (response) {
            if (response.data.status) {
                dispatch(log_in(response.data.user.id))
                dispatch(setUsers([response.data.user]))
                dispatch(addToast('success', 'Inició de sesión exitoso'))
            } else {
                dispatch(addToast('error', 'Las Credenciales no se encuentran en nuestros registros'))
            }
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const logout = () => (dispatch) => {
    dispatch(log_out())
}

export const fetch_menu = (rol) => (dispatch) => {
    axios.get(`${APIpath}/menu/${rol}`)
        .then(function (response) {
            if (response.data) {
                dispatch(set_menu(response.data))
            }
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}