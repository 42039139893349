import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { updateUser, updateUserPassword, fetchRoles } from '../actions'
import {
    getUser, getEntity
} from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    Heading,
    CircularProgress,
    useColorModeValue,
    Select,
    Spinner
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'


const UserEdit = props => {


    useEffect(() => {
        const { roles, fetchRoles } = props
        if (roles.length === 0) fetchRoles('roles')
    })

    const { user, roles, updateUser, updateUserPassword } = props
    return (
        <Layout>
            <Box
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'md'}
                m={3}
                p={6}
            >
                <Heading>Datos personales </Heading>
                {Object.keys(user).length === 0 ?
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                    :
                    <Formik
                        initialValues={{
                            nombre: user.nombre,
                            apellido: user.apellido,
                            rol_id: user.rol_id,
                            zona_residencial: user.zona
                        }}
                        validate={values => FormValidator([
                            { field: 'nombre', value: values.nombre, rules: ['required'] },
                            { field: 'apellido', value: values.apellido, rules: ['required'] },
                            { field: 'rol_id', value: values.rol_id, rules: ['required'], lang: 'Rol' }
                        ])}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            Promise.resolve(updateUser({ ...values, id: user.id }))
                                .then(setSubmitting(false))
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>
                                <FormControl isInvalid={errors.nombre && touched.nombre}>
                                    <FormLabel>Nombre</FormLabel>
                                    <Input type="text" name='nombre' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombre} />
                                    <FormErrorMessage>{errors.nombre}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.apellido && touched.apellido}>
                                    <FormLabel>Apellido</FormLabel>
                                    <Input type={'text'} size="lg" name={"apellido"} onChange={handleChange} onBlur={handleBlur} value={values.apellido} />
                                    <FormErrorMessage>{errors.apellido}</FormErrorMessage>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Correo</FormLabel>
                                    <Input type={'text'} size="lg" value={user.email} disabled />
                                </FormControl>
                                <FormControl isInvalid={errors.zona_residencial && touched.zona_residencial}>
                                    <FormLabel>Zona residencial</FormLabel>
                                    <Select size="lg" name={"zona_residencial"} onChange={handleChange} onBlur={handleBlur} value={values.zona_residencial} >
                                        <option value={""}>Seleccione zona</option>
                                        <option value={"Zona Litoral"}>Zona Litoral</option>
                                        <option value={"Zona occidental"}>Zona occidental</option>
                                        <option value={"Zona Norte"}>Zona Norte</option>
                                        <option value={"Zona centro - norte"}>Zona centro - norte</option>
                                        <option value={"Zona Centro"}>Zona Centro</option>
                                        <option value={"Zona oriental - sur"}>Zona oriental - sur</option>
                                        <option value={"Zona Sur"}>Zona Sur</option>
                                        <option value={"Zona oriental - norte"}>Zona oriental - norte</option>
                                    </Select>
                                    <FormErrorMessage>{errors.zona_residencial}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.rol_id && touched.rol_id}>
                                    <FormLabel>Rol</FormLabel>
                                    <Select size="lg" name="rol_id" onChange={handleChange} onBlur={handleBlur} value={values.rol_id} >
                                        <option value={""}>Seleccione rol de usuario</option>
                                        {roles.map(rol =>
                                            <option value={rol.id} key={rol.id} > {rol.nombre}</option>
                                        )}
                                    </Select>
                                    <FormErrorMessage>{errors.rol_id}</FormErrorMessage>
                                </FormControl>
                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                                </Button>
                            </form>
                        )}
                    </Formik>
                }

            </Box>

            <Box
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'md'}
                m={3}
                p={6}
            >
                <Heading>Contraseña</Heading>
                <Formik
                    initialValues={{ password: '', repeatpassword: '' }}
                    validate={values => FormValidator([
                        { field: 'password', value: values.password, rules: ['required'], lang: 'Contraseña' },
                        { field: 'repeatpassword', value: values.repeatpassword, rules: ['required', 'same'], param: values.password, lang: 'repetir contraseña' }
                    ])}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        Promise.resolve(updateUserPassword({ ...values, id: user.id }))
                            .then(setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={errors.password && touched.password}>
                                <FormLabel>Contraseña</FormLabel>
                                <Input type={'password'} size="lg" name={"password"} onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                <FormErrorMessage>{errors.password}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.repeatpassword && touched.repeatpassword}>
                                <FormLabel>Repetir Contraseña</FormLabel>
                                <Input type="password" name='repeatpassword' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.repeatpassword} />
                                <FormErrorMessage>{errors.repeatpassword}</FormErrorMessage>
                            </FormControl>
                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Layout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    user: getUser(state, ownProps.match.params.id),
    roles: getEntity(state, 'roles')
})

const mapDispatchToProps = { updateUser, updateUserPassword, fetchRoles }

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)
