import Authpage from './Authpage'
import Generalpage from './Generalpage'

const LayoutSelection = ({ type, children }) => {

    let Layout
    switch (type) {
        case 'auth':
            Layout = Authpage
            break
        default:
            Layout = Generalpage
            break
    }

    return <Layout >
        {children}
    </Layout>
}

export default LayoutSelection