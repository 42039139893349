import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import { fetchConfiguration, fetchEntity } from './actions'
import PrivateRoute from './containers/PrivateRoute'
import Error404 from './components/404'
import ErrorBoundary from './components/ErrorBoundary'
import Toast from './containers/Toast'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Profile from './pages/Profile'
import UserList from './pages/UserList'
import UserCreate from './pages/UserCreate'
import UserEdit from './pages/UserEdit'
import ClientCreate from './pages/ClientCreate'
import ClientEdit from './pages/ClientEdit'
import ClientList from './pages/ClientList'
import BandejaAdministrador from './pages/BandejaAdministrador'
import BandejaUsuario from './pages/BandejaUsuario'
import BandejaDetail from './pages/BandejaDetail'
import UserClientList from './pages/UserClientList'
import CarCreate from './pages/CarCreate'
import CarEdit from './pages/CarEdit'
import CarList from './pages/CarList'
import RequestList from './pages/RequestList'
import RequestView from './pages/RequestView'
import TestimonialList from './pages/TestimonialList'
import TestimonialCreate from './pages/TestimonialCreate'
import TestimonialEdit from './pages/TestimonialEdit'
import Configuration from './pages/Configuration'


class App extends React.Component {

  componentDidMount() {
    this.props.fetchConfiguration()
    this.props.fetchEntity('departamento')
  }

  render() {
    return (
      <Router>
        <ErrorBoundary>
          <Switch>
            <Route path={"/login"} exact component={Login} />
            <PrivateRoute path={"/"} exact component={Dashboard} />
            <PrivateRoute path={"/profile"} exact component={Profile} />
            <PrivateRoute path={"/user"} exact component={UserList} />
            <PrivateRoute path={"/user/create"} exact component={UserCreate} />
            <PrivateRoute path={"/user/:id/edit"} component={UserEdit} />
            <PrivateRoute path={"/user/:id/bandeja"} component={BandejaUsuario} />
            <PrivateRoute path={"/client/create"} exact component={ClientCreate} />
            <PrivateRoute path={"/client/:id/edit"} component={ClientEdit} />
            <PrivateRoute path={"/client"} exact component={ClientList} />
            <PrivateRoute path={"/client/user/list"} exact component={UserClientList} />
            <PrivateRoute path={"/bandeja-list"} exact component={BandejaAdministrador} />
            <PrivateRoute path={"/client/:id/bandeja"} exact component={BandejaDetail} />
            <PrivateRoute path={"/car"} exact component={CarList} />
            <PrivateRoute path={"/car/create"} exact component={CarCreate} />
            <PrivateRoute path={"/car/:id/edit"} exact component={CarEdit} />
            <PrivateRoute path={"/request"} exact component={RequestList} />
            <PrivateRoute path={"/solicitud/:id/view"} component={RequestView} />
            <PrivateRoute path={"/config"} exact component={Configuration} />
            <PrivateRoute path={"/testimonial"} exact component={TestimonialList} />
            <PrivateRoute path={"/testimonial/:id/edit"} component={TestimonialEdit} />
            <PrivateRoute path={"/testimonial/create"} component={TestimonialCreate} />
            <Route path={"*"} component={Error404} />
          </Switch>
          <Toast />
        </ErrorBoundary>
      </Router>
    )
  }

}

const mapStateToProps = (state) => ({
  loader: false
})

const mapDispatchToProps = { fetchConfiguration, fetchEntity }

export default connect(mapStateToProps, mapDispatchToProps)(App)