import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { saveUser, fetchRoles } from '../actions'
import { getEntity, getRedirect } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    Heading,
    CircularProgress,
    useColorModeValue,
    Select
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'


const UserCreate = props => {


    useEffect(() => {
        const { roles, fetchRoles } = props
        if (roles.length === 0) fetchRoles('roles')
    })

    const { roles, saveUser, redirect } = props

    return (
        <Layout>
            {redirect && <Redirect to="/user" />}
            <Box
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'md'}
                m={3}
                p={6}
            >
                <Heading>Datos personales </Heading>
                <br />
                <Formik
                    initialValues={{
                        nombre: '',
                        apellido: '',
                        email: '',
                        rol_id: '',
                        password: '',
                        zona_residencial: ''
                    }}
                    validate={values => FormValidator([
                        { field: 'nombre', value: values.nombre, rules: ['required'] },
                        { field: 'apellido', value: values.apellido, rules: ['required'] },
                        { field: 'email', value: values.email, rules: ['required'] },
                        { field: 'rol_id', value: values.rol_id, rules: ['required'], lang: 'Rol' },
                        { field: 'password', value: values.password, rules: ['required'] }
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        Promise.resolve(saveUser(values))
                            .then(() => {
                                setSubmitting(false)
                                resetForm()
                            })
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={errors.nombre && touched.nombre}>
                                <FormLabel>Nombre</FormLabel>
                                <Input type="text" name='nombre' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombre} />
                                <FormErrorMessage>{errors.nombre}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.apellido && touched.apellido}>
                                <FormLabel>Apellido</FormLabel>
                                <Input type={'text'} size="lg" name={"apellido"} onChange={handleChange} onBlur={handleBlur} value={values.apellido} />
                                <FormErrorMessage>{errors.apellido}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.email && touched.email}>
                                <FormLabel>Correo</FormLabel>
                                <Input type={'email'} size="lg" name={"email"} onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.zona_residencial && touched.zona_residencial}>
                                <FormLabel>Zona residencial</FormLabel>
                                <Select size="lg" name={"zona_residencial"} onChange={handleChange} onBlur={handleBlur} value={values.zona_residencial} >
                                    <option value={""}>Seleccione zona</option>
                                    <option value={"Zona Litoral"}>Zona Litoral</option>
                                    <option value={"Zona occidental"}>Zona occidental</option>
                                    <option value={"Zona Norte"}>Zona Norte</option>
                                    <option value={"Zona centro - norte"}>Zona centro - norte</option>
                                    <option value={"Zona Centro"}>Zona Centro</option>
                                    <option value={"Zona oriental - sur"}>Zona oriental - sur</option>
                                    <option value={"Zona Sur"}>Zona Sur</option>
                                    <option value={"Zona oriental - norte"}>Zona oriental - norte</option>
                                </Select>
                                <FormErrorMessage>{errors.zona_residencial}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.rol_id && touched.rol_id}>
                                <FormLabel>Rol</FormLabel>
                                <Select size="lg" name="rol_id" onChange={handleChange} onBlur={handleBlur} value={values.rol_id} >
                                    <option value={""}>Seleccione rol de usuario</option>
                                    {roles.map(rol =>
                                        <option value={rol.id} key={rol.id} > {rol.nombre}</option>
                                    )}
                                </Select>
                                <FormErrorMessage>{errors.rol_id}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.password && touched.password}>
                                <FormLabel>Contraseña</FormLabel>
                                <Input type={'password'} size="lg" name={"password"} onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                <FormErrorMessage>{errors.password}</FormErrorMessage>
                            </FormControl>
                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Crear'}
                            </Button>
                        </form>
                    )}
                </Formik>

            </Box>
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    roles: getEntity(state, 'roles'),
    redirect: getRedirect(state)
})

const mapDispatchToProps = { saveUser, fetchRoles }

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
