import React from "react"
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createTestimonial } from '../actions'
import { getRedirect } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    useColorModeValue,
    Textarea,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import InputFile from "../components/InputFile"


class TestimonialCreate extends React.Component {

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {

        const { createTestimonial, redirect } = this.props
        return (
            <Layout>
                {redirect && <Redirect to="/testimonial" />}
                <Box w={'full'} bg={this.getColor} rounded={'md'} m={3} p={6} >
                    <Formik
                        initialValues={{
                            titulo: '',
                            descripcion: '',
                            image: null
                        }}
                        validate={values => FormValidator([
                            { field: 'titulo', value: values.titulo, rules: ['required'] },
                            { field: 'descripcion', value: values.descripcion, rules: ['required'] },
                            { field: 'image', value: values.image, rules: ['required'] },
                        ])}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true)
                            const formData = new FormData()
                            for (let value in values) {
                                formData.append(value, values[value])
                            }
                            Promise.resolve(createTestimonial(formData))
                                .then(() => {
                                    setSubmitting(false)
                                    resetForm()
                                })
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>

                                <FormControl isInvalid={errors.titulo && touched.titulo}>
                                    <FormLabel>Titulo</FormLabel>
                                    <Input name="titulo" onChange={handleChange} onBlur={handleBlur} value={values.titulo} />
                                    <FormErrorMessage>{errors.titulo}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={errors.image && touched.image}>
                                    <FormLabel>Imagen</FormLabel>
                                    <InputFile name={"image"} label={"Imágen"} setFieldValue={setFieldValue} value={values.image} />
                                    <FormErrorMessage>{errors.image}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={errors.descripcion && touched.descripcion}>
                                    <FormLabel>Descripción</FormLabel>
                                    <Textarea size="lg" name={"descripcion"} onChange={handleChange} onBlur={handleBlur} value={values.descripcion} />
                                    <FormErrorMessage>{errors.descripcion}</FormErrorMessage>
                                </FormControl>

                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress /> : 'Crear'}
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    redirect: getRedirect(state)
})

const mapDispatchToProps = { createTestimonial }

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialCreate)
