const ui = (state = {
    loader: false,
    redirect: false,
    toast: { id: 'default', active: false, message: '', status: 'error' },
    menu: []
}, action) => {

    switch (action.type) {
        case 'LOADING': return { ...state, loader: action.status }
        case 'REDIRECT': return { ...state, redirect: action.status }
        case 'FETCH_MENUS': return { ...state, menu: action.menu }
        case 'ADD_TOAST': return { ...state, toast: { id: action.id, active: true, message: action.message, status: action.status } }
        case 'REMOVE_TOAST': return { ...state, toast: { id: 'default', active: false, message: '', status: 'error' } }
        case 'LOG_OUT': return { ...state, menu: [] }
        default: return state
    }
}

export const getLoader = (state) => state.loader
export const getRedirect = (state) => state.redirect
export const getMenu = (state) => state.menu
export const getActiveToast = (state) => state.toast

export default ui