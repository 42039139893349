import {
    LOG_IN,
    LOG_OUT
} from '../actions'

const auth = (state = { uid: undefined }, action) => {
    switch (action.type) {
        case LOG_IN: 
            return { uid: action.uid }
        case LOG_OUT: return { uid: undefined }
        default: return state
    }
}

export const getAuth = (state) => state.uid
export const getRole = (state) => state.role

export default auth