import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom"
import { login } from '../actions'
import { getAuth } from '../reducers'
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    Image,
    CircularProgress
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import Logo from '../multimedia/logo.png'

const Login = (props) => {
    const { login, isAuth } = props

    if (isAuth) {
        return <Redirect to={'/'} />
    }

    return <Layout type="auth">
        <Image src={Logo} maxH={300} width={"auto"}  />
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => FormValidator([
                { field: 'email', value: values.email, rules: ['required', 'email'] },
                { field: 'password', value: values.password, rules: ['required'] }
            ])}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                Promise.resolve(login(values))
                    .then(setSubmitting(false))
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <FormControl isInvalid={errors.email && touched.email}>
                        <FormLabel>Email</FormLabel>
                        <Input type="email" name='email' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.password && touched.password}>
                        <FormLabel>Password</FormLabel>
                        <Input type={'password'} size="lg" name={"password"} placeholder="*******" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <Button colorScheme="teal" variant="outline" type="submit" width="full" mt={4} disabled={isSubmitting}>
                        {isSubmitting ? <CircularProgress /> : 'Iniciar sesión'}
                    </Button>
                </form>
            )}
        </Formik>
    </Layout>
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true
})

const mapDispatchToProps = ({ login })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))