import React from "react"
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { fetchSolicitudes, fetchEntity } from '../actions'
import { getSolicitudes, getEntity } from '../reducers'
import Layout from '../layout'
import {
    Flex,
    Box,
    useColorModeValue,
    Text,
    Button
} from '@chakra-ui/react'
import DataTable from 'react-data-table-component'

const columns = [
    { name: 'Fecha', selector: row => row.fechaCreacion, sortable: true },
    { name: 'Nombre', selector: row => row.nombre, sortable: true },
    { name: 'Estado', selector: row => row.estado, sortable: true },
    { name: 'Etapa', selector: row => row.etapa, sortable: true },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
];

class RequestList extends React.Component {

    componentDidMount() {
        this.props.fetchSolicitudes()
        this.props.fetchEntity('financiamiento')
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {

        const { financiamiento_estados, solicitudes } = this.props

        return (
            <Layout>
                <Flex flexDirection={"column"} alignItems={"center"} w={'100%'} >
                    <Box
                        bg={this.getColor}
                        rounded={'md'}
                        m={3}
                        p={6}
                        w={"full"}
                    >
                        <Text fontSize={"lg"} >Solicitudes envíadas</Text>
                        <br />

                        <DataTable
                            pagination
                            columns={columns}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            data={solicitudes.map(solicitud => {
                                return {
                                    id: solicitud.id,
                                    fechaCreacion: new Date(solicitud.dFechaCreacion).toISOString().split('T')[0],
                                    nombre: `${solicitud.cNombres} ${solicitud.cNombres2 || ''} ${solicitud.cApellidoPaterno} ${solicitud.cApellidoMaterno || ''}`,
                                    estado: financiamiento_estados.reduce((text, estado) => {
                                        if (estado.id === solicitud.cEstadoFinanciamiento) return estado.nombre
                                        return text
                                    }, 'En espera de análisis'),
                                    etapa: solicitud.cEtapa || 'En Proceso',
                                    opcion: <Link to={`solicitud/${solicitud.id}/view`}><Button colorScheme="teal" variant="outline">Detalle</Button></Link>
                                }
                            })}
                        />
                    </Box>
                </Flex>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    financiamiento_estados: getEntity(state, 'financiamiento'),
    solicitudes: getSolicitudes(state)
})

const mapDispatchToProps = { fetchSolicitudes, fetchEntity }

export default connect(mapStateToProps, mapDispatchToProps)(RequestList)