import axios from 'axios'
import {
    FETCH_CAR,
    FETCH_CARS,
    APIpath,
    setRedirect,
    addToast
} from '../actions'

const setCars = (cars) => ({
    type: FETCH_CARS,
    cars
})

const setCar = (car) => ({
    type: FETCH_CAR,
    car
})

export const fetchCars = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/carro`)
        .then(function (response) {
            dispatch(setCars(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const saveCar = (values) => dispatch => {
    axios.post(`${APIpath}/carro/store`, values)
        .then(function (response) {
            dispatch(setRedirect(true))
            dispatch(addToast('success', 'Carro creado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateCar = (values) => dispatch => {
    axios.post(`${APIpath}/carro/update`, values)
        .then(function (response) {
            dispatch(setCar(response.data[0]))
            dispatch(addToast('success', 'Carro actualizado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const carImage = (values, id) => (dispatch) => {
    //Identificamos si hay registro para ese vehiculo
    axios.get(`${APIpath}/carro/image/${id}`)
        .then(function (response) {
            //Evaluamos si hay registro
            response.data.length === 0 ?
                axios.post(`${APIpath}/carro/image/store`, values)
                    .then(function (response) {
                        dispatch(addToast('success', 'Imagen guardada'))
                    })
                    .catch(function (err) {
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
                :
                axios.post(`${APIpath}/carro/image/update`, values)
                    .then(function (response) {
                        dispatch(addToast('success', 'Imagen guardada'))
                    })
                    .catch(function (err) {
                        dispatch(addToast('error', 'El proceso ha fallado'))
                    })
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}