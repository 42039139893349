import React from 'react'
import { Image } from '@chakra-ui/react'

class Thumb extends React.Component {
    state = {
        loading: false,
        thumb: undefined,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.file !== this.props.file)
            this.setState({ loading: true }, () => {
                let reader = new FileReader()
                reader.onloadend = () => { this.setState({ loading: false, thumb: reader.result }) }
                reader.readAsDataURL(this.props.file)
            })
    }

    render() {
        const { file } = this.props
        const { loading, thumb } = this.state
        if (!file) { return null }
        if (loading) { return <p>loading...</p> }
        return (<Image src={thumb} boxSize={"150px"} alt={file.name} />)
    }
}

export default Thumb