const bandeja= (state = [], action) => {

    switch(action.type){
        case 'FETCH_BANDEJA': return action.bandeja 
        case 'LOG_OUT': return []
        default: return state
    }
}

export const getBandeja = (state) => state
export const getBandejaUsuario = (state, id) => state.reduce((p, bandeja) => {
    if (bandeja.cliente_id === Number(id)) return bandeja
    return p
}, null)

export default bandeja