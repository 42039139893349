import React from "react"
import {
    FormControl,
    FormErrorMessage,
    Button,
    CircularProgress,
    Box,
    Divider, Image
} from '@chakra-ui/react'
import InputFile from "./InputFile"
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import { APIpath } from '../actions'
import placeholder from '../multimedia/placeholder.jpg'

const replaceImage = (error) => {
    error.target.src = placeholder;
}

export const CarImageForm = (props) => {

    const { car, carImage } = props
    return (
        <Box w={"full"}>
            <Formik
                initialValues={{ image: null }}
                validate={values => FormValidator([
                    { field: 'image', value: values.image, rules: ['required'], lang: 'Foto delantera' }
                ])}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    for (let value in values) {
                        formData.append(value, values[value])
                    }
                    formData.append('id', car.id)
                    formData.append('order', 1)
                    Promise.resolve(carImage(formData, car.id))
                        .then(() => {
                            setSubmitting(false)
                            resetForm()
                        })
                }}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <Image src={`${APIpath}/carro/image/${car.id}/1?${Math.random().toString(36)}`} alt={"preview" + car.id} key={car.id} h={"200px"} w={"auto"} m={"auto"} onError={replaceImage}/>
                        <FormControl isInvalid={errors.image && touched.image}>
                            <InputFile name={"image"} label={"Foto delantera"} setFieldValue={setFieldValue} value={values.image} />
                            <FormErrorMessage>{errors.image}</FormErrorMessage>
                        </FormControl>

                        <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                        </Button>
                    </form>
                }
            </Formik>
            <Divider my={3} />
            <Formik
                initialValues={{ image: null }}
                validate={values => FormValidator([
                    { field: 'image', value: values.image, rules: ['required'], lang: 'Foto lateral' }
                ])}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    for (let value in values) {
                        formData.append(value, values[value])
                    }
                    formData.append('id', car.id)
                    formData.append('order', 2)
                    Promise.resolve(carImage(formData, car.id))
                        .then(() => {
                            setSubmitting(false)
                            resetForm()
                        })
                }}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">

                        <Image src={`${APIpath}/carro/image/${car.id}/2?${Math.random().toString(36)}`} alt={"preview" + car.id} key={car.id} h={"200px"} w={"auto"} m={"auto"} onError={replaceImage} />
                        <FormControl isInvalid={errors.image && touched.image}>
                            <InputFile name={"image"} label={"Foto lateral"} setFieldValue={setFieldValue} value={values.image} />
                            <FormErrorMessage>{errors.image}</FormErrorMessage>
                        </FormControl>

                        <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                        </Button>
                    </form>
                }
            </Formik>
            <Divider my={3} />
            <Formik
                initialValues={{ image: null }}
                validate={values => FormValidator([
                    { field: 'image', value: values.image, rules: ['required'], lang: 'Foto trasera' }
                ])}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    for (let value in values) {
                        formData.append(value, values[value])
                    }
                    formData.append('id', car.id)
                    formData.append('order', 3)
                    Promise.resolve(carImage(formData, car.id))
                        .then(() => {
                            setSubmitting(false)
                            resetForm()
                        })
                }}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">

                        <Image src={`${APIpath}/carro/image/${car.id}/3?${Math.random().toString(36)}`} alt={"preview" + car.id} key={car.id} h={"200px"} w={"auto"} m={"auto"} onError={replaceImage} />
                        <FormControl isInvalid={errors.image && touched.image}>
                            <InputFile name={"image"} label={"Foto trasera"} setFieldValue={setFieldValue} value={values.image} />
                            <FormErrorMessage>{errors.image}</FormErrorMessage>
                        </FormControl>

                        <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                        </Button>
                    </form>
                }
            </Formik>
            <Divider my={3} />
            <Formik
                initialValues={{ image: null }}
                validate={values => FormValidator([
                    { field: 'image', value: values.image, rules: ['required'], lang: 'Foto motor' }
                ])}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    for (let value in values) {
                        formData.append(value, values[value])
                    }
                    formData.append('id', car.id)
                    formData.append('order', 4)
                    Promise.resolve(carImage(formData, car.id))
                        .then(() => {
                            setSubmitting(false)
                            resetForm()
                        })
                }}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">

                        <Image src={`${APIpath}/carro/image/${car.id}/4?${Math.random().toString(36)}`} alt={"preview" + car.id} key={car.id} h={"200px"} w={"auto"} m={"auto"} onError={replaceImage} />
                        <FormControl isInvalid={errors.image && touched.image}>
                            <InputFile name={"image"} label={"Foto motor"} setFieldValue={setFieldValue} value={values.image} />
                            <FormErrorMessage>{errors.image}</FormErrorMessage>
                        </FormControl>

                        <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                        </Button>
                    </form>
                }
            </Formik>
            <Divider my={3} />
            <Formik
                initialValues={{ image: null }}
                validate={values => FormValidator([
                    { field: 'image', value: values.image, rules: ['required'], lang: 'Foto Kilometraje' }
                ])}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    const formData = new FormData()
                    for (let value in values) {
                        formData.append(value, values[value])
                    }
                    formData.append('id', car.id)
                    formData.append('order', 5)
                    Promise.resolve(carImage(formData, car.id))
                        .then(() => {
                            setSubmitting(false)
                            resetForm()
                        })
                }}
            >
                {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue }) =>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">

                        <Image src={`${APIpath}/carro/image/${car.id}/5?${Math.random().toString(36)}`} alt={"preview" + car.id} key={car.id} h={"200px"} w={"auto"} m={"auto"} onError={replaceImage} />
                        <FormControl isInvalid={errors.image && touched.image}>
                            <InputFile name={"image"} label={"Foto Kilometraje"} setFieldValue={setFieldValue} value={values.image} />
                            <FormErrorMessage>{errors.image}</FormErrorMessage>
                        </FormControl>

                        <Button colorScheme={"easycar2"} variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress /> : 'Guardar'}
                        </Button>
                    </form>
                }
            </Formik>
        </Box>
    )
}