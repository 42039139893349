const client= (state = [], action) => {

    switch(action.type){
        case 'FETCH_CLIENT': 
        return state.map( client => {
            if(action.client.id === client.id) return action.client
            return client
        })
        case 'FETCH_CLIENTS': return action.clients 
        case 'LOG_OUT': return []
        default: return state
    }
}

export const getClients = (state) => state
export const getClient = (state, id) => state.reduce((p, client) => {
    if (client.id === Number(id)) return client
    return p
}, {})


export default client