import axios from 'axios'
import {
    FETCH_SOLICITUDES,
    FETCH_SOLICITUD,
    APIpath,
    addToast
} from '../actions'

const setSolicitudes = (solicitudes) => ({
    type: FETCH_SOLICITUDES,
    solicitudes
})

const setSolicitud = (solicitud) => ({
    type: FETCH_SOLICITUD,
    solicitud
})

export const fetchSolicitudes = () => (dispatch) => {
    axios.get(`${APIpath}/solicitud/`)
        .then(function (response) {
            dispatch(setSolicitudes(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchSolicitudesCliente = (id) => (dispatch) => {
    axios.get(`${APIpath}/solicitud/cliente/${id}`)
        .then(function (response) {
            dispatch(setSolicitudes(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateSolicitud = (values) => (dispatch) => {
    axios.post(`${APIpath}/solicitud/update`, values)
        .then(function (response) {
            dispatch(setSolicitud(response.data))
            dispatch(addToast('success', 'cambio guardado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}