import React from "react"
import Layout from '../layout'
import { Flex } from '@chakra-ui/react'

const Dashboard = (props) => {

    return (
        <Layout >
            <Flex>

            </Flex>
        </Layout>
    )
}

export default Dashboard