import React from 'react'

import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  useColorMode
} from '@chakra-ui/react'
import NavItem from './NavItem'
import Logo from '../multimedia/logo.png'
import LogoDark from '../multimedia/logo2.png'


const Sidebar = ({ onClose, linksItems, ...rest }) => {
  const { colorMode } = useColorMode()
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflow={"auto"}
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <img src={colorMode === 'light' ? Logo : LogoDark} alt="logo" />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {linksItems.map((link) => (
        <NavItem key={link.id} icon={link.icon} path={link.path}>{link.nombre} </NavItem>
      ))}
    </Box>
  )
}

export default Sidebar