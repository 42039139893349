import React from "react"
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { fetchBandeja } from '../actions'
import { getBandeja } from '../reducers'
import {
    Box,
    Button,
    Flex,
    useColorModeValue
} from '@chakra-ui/react'
import Layout from '../layout'
import DataTable from 'react-data-table-component'

const columns = [
    { name: 'Cliente', selector: row => row.nombre, sortable: true },
    { name: 'Fecha Asignación', selector: row => row.created_at, sortable: true },
    { name: 'Fecha Atención', selector: row => row.fecha_accion, sortable: true },
    { name: 'Estado', selector: row => row.estado, sortable: true },
    { name: 'Opciones', selector: row => row.opcion, sortable: false },
    { name: '', selector: row => row.opcion2, sortable: false },
];

class BandejaAdministrador extends React.Component {

    componentDidMount() {
        this.props.fetchBandeja()
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {
        const { clients } = this.props

        return (
            <Layout>
                <Flex>

                    <Box
                        w={'full'}
                        bg={this.getColor}
                        rounded={'md'}
                        m={3}
                        p={6}
                    >
                        <DataTable
                            pagination
                            columns={columns}
                            defaultSortAsc={true}
                        defaultSortFieldId={1}
                            data={clients.map(client => {
                                return {
                                    id: client.id,
                                    nombre: client.cliente,
                                    created_at: new Date(client.created_at).toISOString().split('T')[0],
                                    fecha_accion: client.fecha_accion ? new Date(client.fecha_accion).toISOString().split('T')[0] : 'En espera',
                                    estado: client.estado || 'Pendiente',
                                    opcion: <Link to={`/client/${client.cliente_id}/edit`}><Button colorScheme="teal" variant="outline">Perfil</Button></Link>,
                                    opcion2:    <Link to={`/client/${client.cliente_id}/bandeja`}><Button colorScheme="easycar2" variant="outline">Acciones</Button></Link>
                                }
                            })}
                        />
                    </Box>
                </Flex >
            </Layout >
        )
    }
}

const mapStateToProps = (state) => ({
    clients: getBandeja(state)
})

const mapDispatchToProps = { fetchBandeja }

export default connect(mapStateToProps, mapDispatchToProps)(BandejaAdministrador)