import axios from 'axios'
import {
    FETCH_TESTIMONIALS,
    APIpath,
    addToast,
    setRedirect
} from '../actions'

const setTestimonials = (testimonials) => ({
    type: FETCH_TESTIMONIALS,
    testimonials
})

export const fetchTestimonials = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/testimonial/`)
        .then(function (response) {
            dispatch(setTestimonials(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const fetchActiveTestimonials = () => (dispatch) => {
    dispatch(setRedirect(false))
    axios.get(`${APIpath}/testimonial/active-testimonial`)
        .then(function (response) {
            dispatch(setTestimonials(response.data))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const createTestimonial = (values) => (dispatch) => {
    axios.post(`${APIpath}/testimonial/store`, values)
        .then(function (response) {
            dispatch(setRedirect(true))
            dispatch(addToast('success', 'Elemento creado'))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateTestimonial = (values) => (dispatch) => {
    axios.post(`${APIpath}/testimonial/update`, values)
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            window.location.reload(false);
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}

export const updateStatusTestimonial = (id) => dispatch => {
    axios.post(`${APIpath}/testimonial/status`, { id })
        .then(function (response) {
            dispatch(addToast('success', 'Elemento actualizado'))
            dispatch(setRedirect(true))
        })
        .catch(function (err) {
            dispatch(addToast('error', 'El proceso ha fallado'))
        })
}