import React from 'react'
import { connect } from 'react-redux'
import { logout, fetch_menu } from '../actions'
import { getUser, getAuth, getMenu } from '../reducers'
import { Box, Flex, useColorModeValue } from "@chakra-ui/react"
import NavbarPanel from "../containers/NavbarPanel"

class GeneralPage extends React.Component {

    componentDidMount() {
        if (this.props.menu.length === 0) {
            this.props.fetch_menu(this.props.user.rol_id)
        }
    }

    getColor = () => useColorModeValue('gray.100', 'gray.900')

    render() {

        const { children } = this.props

        return (
            <Box minH="100vh" bg={this.getColor}>
                <NavbarPanel user={this.props.user} menu={this.props.menu} logout={this.props.logout} />
                <Flex minH={'100vh'} align={'start'} justify={'center'}>
                    <Box w={"full"} ml={{ base: 0, md: 60 }} mr={{ base: 0, md: 20 }} p="4">
                        {children}
                    </Box>
                </Flex>
            </Box>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true,
    user: getUser(state, getAuth(state)),
    menu: getMenu(state)
})

const mapDispatchToProps = ({ logout, fetch_menu })

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPage)